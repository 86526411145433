import { SyntheticEvent, useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  email: string
  password: string
}

export default function LoginForm() {
  const navigate = useNavigate()
  const { setToken } = useContext(AuthContext)
  const [form, setForm] = useState<Form>({
    email: '',
    password: '',
  })
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const loginMutation = useMutation<
    {
      message: string
      data?: {
        traceId: string
        via: UserTwoFa
        token: string
      }
    },
    Error,
    Form
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post(
          '/user/auth/login',
          payload,
        )
        return res.data
      } catch (err: any) {
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        if (data?.data?.traceId) {
          navigate('/verify-otp', { state: data.data })
        }
        if (data.data?.token) {
          localStorage.setItem('token', data.data.token)
          setToken(data.data.token)
        }
      },
    },
  )

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    if (!form.email || !form.password) return toast.error('Please fill all the fields')

    toast.promise(loginMutation.mutateAsync(form), {
      loading: 'Logging in...',
      success: (res) => res.message || 'Logged in successfully',
      error: (err) => err?.message || 'Failed to login',
    })
  }

  return (
    <>
      <form className='flex flex-col gap-6' onSubmit={onSubmit}>
        <Input onChange={changeHandler} value={form.email} label='Email' name='email' />
        <Input
          onChange={changeHandler}
          value={form.password}
          label='Password'
          name='password'
          type={isPasswordVisible ? 'text' : 'password'}
          afterFix={
            <InputAfterFixShowHider
              isVisible={isPasswordVisible}
              onClick={() => setIsPasswordVisible((prev) => !prev)}
              type='eye'
            />
          }
        />

        <div className='flex justify-end'>
          <Link
            to='/forgot-password'
            className='text-xs hover:text-primary transition-all text-white'
          >
            Forgot Password?
          </Link>
        </div>
        <Button fullWidth disabled={loginMutation.isLoading}>
          Log In
        </Button>
      </form>
    </>
  )
}
