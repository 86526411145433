import clsx from 'clsx'

type Props = {
  options: TabOption[]
  selected: TabOption
  className?: string
  selectHandler: (selected: TabOption) => void
}

export default function Tabs({ options, selected, className = '', selectHandler }: Props) {
  return (
    <ul
      className={clsx('inline-flex flex-wrap rounded-full overflow-hidden bg-[#2A2532]', className)}
    >
      {options?.map((option) => (
        <li
          onClick={() => selectHandler(option)}
          className={clsx(
            'h-[35px] grid place-items-center px-6 font-semibold text-sm cursor-pointer text-white rounded-full',
            {
              '!text-dark bg-primary': selected.value === option.value,
            },
          )}
          key={option.value}
        >
          {option.label}
        </li>
      ))}
    </ul>
  )
}
