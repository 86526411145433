import { SyntheticEvent, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'

import Button from 'components/form/Button'
import Input from 'components/form/Input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import { useLocation, useNavigate } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  username: string
  email: string
  password: string
  referralId?: string
}

export default function RegisterForm() {
  const navigate = useNavigate()
  const location = useLocation() // hook from 'react-router-dom' to access location
  const urlParams = new URLSearchParams(location.search)
  const sponsorFromUrl = urlParams.get('referralId')

  const [form, setForm] = useState<Form>({
    username: '',
    email: '',
    password: '',
  })
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [errors, setErrors] = useState<Form>({
    username: '',
    email: '',
    password: '',
  })

  useEffect(() => {
    if (sponsorFromUrl) {
      setForm((prev) => ({
        ...prev,
        referralId: sponsorFromUrl,
      }))
    }
  }, [sponsorFromUrl])

  const registerMutation = useMutation<{ token: string; message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post(
          'user/auth/register',
          payload,
        )
        return res.data
      } catch (err: any) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        navigate('/registration-successful')
      },
    },
  )

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
    setErrors((prev) => ({
      ...prev,
      [e.target.name]: '',
    }))
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()

    if (!form.username || !form.email || !form.password || !form.referralId) {
      setErrors({
        username: form.username ? '' : 'Username is required',
        email: form.email ? '' : 'Email is required',
        password: form.password ? '' : 'Password is required',
        referralId: form.referralId ? '' : 'Referral Id is required',
      })
      return
    }

    toast.promise(registerMutation.mutateAsync(form), {
      loading: 'Registering...',
      success: (res) => res.message || 'Registration successful',
      error: (err) => err?.message || 'Failed to register',
    })
  }

  return (
    <>
      <form className='flex flex-col gap-6' onSubmit={onSubmit}>
        <Input
          onChange={changeHandler}
          value={form.username}
          label='Username'
          name='username'
          error={!!errors.username}
          helpText={errors.username}
          required
        />
        <Input
          onChange={changeHandler}
          value={form.email}
          label='Email'
          type='email'
          name='email'
          error={!!errors.email}
          helpText={errors.email}
          required
        />
        <Input
          onChange={changeHandler}
          value={form.password}
          label='Password'
          name='password'
          type={isPasswordVisible ? 'text' : 'password'}
          afterFix={
            <InputAfterFixShowHider
              isVisible={isPasswordVisible}
              onClick={() => setIsPasswordVisible((prev) => !prev)}
              type='eye'
            />
          }
          error={!!errors.password}
          helpText={errors.password}
          required
        />
        <Input
          onChange={changeHandler}
          value={form.referralId}
          label='Referral Id'
          name='referralId'
          disabled={!!sponsorFromUrl}
          required={true}
        />

        <p className='text-sm text-white'>
          By creating account you’re agree to{' '}
          <a
            className='text-primary hover:underline'
            href={`${process.env.REACT_APP_WEB_URL}/privacy-policy`}
          >
            Privacy & Policy,{' '}
          </a>
          <a
            className='text-primary hover:underline'
            href={`${process.env.REACT_APP_WEB_URL}/terms-and-conditions`}
          >
            Terms & Conditions
          </a>
        </p>
        <Button fullWidth disabled={registerMutation.isLoading}>
          Create Account
        </Button>
      </form>
    </>
  )
}
