import Devider from 'components/Devider'
import Analytics from 'features/my-network/Analytics'
import ReferralList from 'features/my-network/ReferralList'

export default function MyNetwordPage() {
  return (
    <>
      <Analytics />
      <Devider />
      <ReferralList />
    </>
  )
}
