import { privateRequest } from 'config/axios.config'
import AnalyticCard from './AnalyticCard'

import forexIcon from 'images/analytics/forex.svg'
import stocksIcon from 'images/analytics/stocks.svg'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function Analytics() {
  const { data } = useQuery<ReferralStatistics, Error>('referrals-statistics', async () => {
    try {
      const res = await privateRequest.get('user/sponsor/getList')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='grid sm:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 gap-3'>
      <AnalyticCard icon={forexIcon} title='Total Members' value={`${data?.totalMembers ?? 0}`} />
      <AnalyticCard
        icon={stocksIcon}
        title='Total Referrals'
        value={`${data?.totalMyReferrals ?? 0}`}
      />
    </div>
  )
}
