type Props = React.SVGProps<SVGSVGElement>

export default function AIChatIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C6.48 2 2 6.03 2 10.99c0 2.73 1.29 5.19 3.35 6.93L4 22l4.74-2.07C10.16 20.3 11.05 20.5 12 20.5c5.52 0 10-4.03 10-9.51C22 6.03 17.52 2 12 2Zm-1 13H7v-2h4v2Zm6-4H7V9h10v2Z'
        fill='currentColor'
      />
    </svg>
  )
}
