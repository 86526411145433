import clsx from 'clsx'
import NoData from 'components/NoData'
import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function ReferralList() {
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<ReferralResponse, Error>(
    'referrals',
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(`user/sponsor/getList?page=${pageParam}&limit=20`)
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []
  return (
    <div className='card'>
      <CardTitle title='My Investors' />

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr className='text-left'>
                <th>Level</th>
                <th>Username</th>
                <th>Email</th>
                <th>Date Joined</th>
                {/* <th>Total Members</th> */}
                <th>Investment</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {isLoading &&
                Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <tr key={i}>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      {/* <td>--</td> */}
                      <td>--</td>
                    </tr>
                  ))}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>Level {row.level}</td>
                  <td>{row.username}</td>
                  <td>{row.email}</td>
                  <td>{dateFormatter(row.date)}</td>
                  {/* <td>{row.totalMembers}</td> */}
                  <td>{row.totalInvestment}</td>
                  <td className='capitalize'>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
        {dataList.length === 0 && !isLoading && <NoData />}
      </div>
    </div>
  )
}
