import NiceModal, { useModal } from '@ebay/nice-modal-react'
import copy from 'copy-to-clipboard'
import { LoaderIcon, toast } from 'react-hot-toast'
import { BiCopy } from 'react-icons/bi'
import QrCode from 'react-qr-code'

import GradientText from 'components/GradientText'
import Button from 'components/form/Button'
import Modal from 'components/modal'
import successScreenModal from 'components/modal/successScreen.modal'
import { privateRequest } from 'config/axios.config'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

type Props = {
  investmentId: string
}

interface InvestmentMeta {
  pay_amount: number
  pay_currency: string
}

interface Investment {
  amount: number
  walletAddress: string
  paymentCoin: string
  paymentChain: string
  status: string
  meta: InvestmentMeta[]
}
export default NiceModal.create(({ investmentId }: Props) => {
  const modal = useModal()
  const successScreen = useModal(successScreenModal)
  const {
    data: investment,
    isLoading,
    error,
    refetch,
  } = useQuery<Investment, Error>(
    ['investment-details', investmentId],
    async () => {
      const res = await privateRequest.get(`user/investment/investment-details/${investmentId}`)
      return res.data.data as Investment
    },
    {
      refetchInterval: 10000, // Auto refetch every 10s
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    },
  )

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     investment.mutate()
  //   }, 10000)
  //   return () => clearInterval(interval)
  // }, [])

  const onImageDownload = () => {
    const svg = document.getElementById('QRCode')
    if (!svg) return
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width + 20
      canvas.height = img.height + 20
      if (ctx) {
        ctx.fillStyle = '#fff'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
      }
      ctx?.drawImage(img, 10, 10)
      const pngFile = canvas.toDataURL('image/png')
      const downloadLink = document.createElement('a')
      downloadLink.download = 'QRCode'
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`
  }

  useEffect(() => {
    if (investment?.status === 'active') {
      modal.remove()
      successScreen.show({ title: 'Your payment has been successfully processed.' })
    }
  }, [investment?.status])

  return (
    <Modal
      title='Deposit'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <div className='flex flex-col items-center gap-6 mb-10'>
        <QrCode
          id='QRCode'
          className='!w-48 !h-48 p-2 bg-white'
          value={investment?.walletAddress ?? ''}
        />
        <h2 className='flex items-center flex-wrap gap-2 text-2xl font-medium'>
          <GradientText direction='bg-gradient-to-b'>
            {' '}
            {investment?.meta?.[0]?.pay_amount ?? 'N/A'}
          </GradientText>
          {investment?.paymentCoin}
        </h2>
        <p className='text-xs font-medium text-white'>Network {investment?.paymentChain}</p>
        <p className='text-xs flex flex-wrap items-center gap-4'>
          <GradientText className='break-all'>{investment?.walletAddress}</GradientText>
          <Button
            size='sm'
            onClick={() => {
              copy(investment?.walletAddress ?? '')
              toast.success('Copied to clipboard')
            }}
            className='rounded-md'
          >
            <BiCopy />
          </Button>
        </p>
        <Button onClick={onImageDownload} size='sm' variant='outlined'>
          Save QR Code
        </Button>
        <Button fullWidth>
          <LoaderIcon />
          Awaiting payment from you
        </Button>
      </div>
    </Modal>
  )
})
