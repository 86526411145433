import NiceModal, { useModal } from '@ebay/nice-modal-react'
import GradientText from 'components/GradientText'

import Modal from 'components/modal'
import { useGetAirdrop } from 'queries/airdrop'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import claimAirdropModalModal from './claimAirdrop.modal.modal'

export default NiceModal.create(() => {
  const modal = useModal()
  const claimAirdropModal = useModal(claimAirdropModalModal)

  const { data: airdrops } = useGetAirdrop()

  // const claimedAirdrops = airdrops?.filter((airdrop) => airdrop.status === 'claimed')

  return (
    <Modal
      title='Airdrop HIstory'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-4xl'
    >
      <div className='overflow-x-auto overflow-y-auto max-h-96'>
        <table cellPadding={5} className='min-w-max'>
          <tr>
            <td className='pb-3'>Timestamp</td>
            <td className='pb-3'>Investment ID</td>
            <td className='pb-3'>Airdrop Amount</td>
            <td className='pb-3'>Note</td>
            <td className='pb-3'>Status</td>
            {/* <td className='pb-3'>Action</td> */}
          </tr>
          {airdrops?.map((row) => (
            <tr key={row._id}>
              <td className='py-3'>{dateFormatter(row.createdAt)}</td>
              <td>
                <GradientText>{row.investmentId}</GradientText>
              </td>
              <td>
                {row.amount && row.amount?.toLocaleString()} {row.coin}
              </td>
              <td>{row.note}</td>
              <td className={`capitalize text-${StatusColorFinder(row.status)}`}>{row.status}</td>
              {/* <td>
                {row.status === 'pending' && (
                  <Button
                    onClick={() => claimAirdropModal.show({ airdropId: row._id })}
                    size='sm'
                    className='!rounded-md'
                  >
                    Claim
                  </Button>
                )}
              </td> */}
            </tr>
          ))}
        </table>
      </div>
    </Modal>
  )
})
