import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useState } from 'react'

import GradientText from 'components/GradientText'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'

import Select from 'components/form/Select'
import { privateRequest } from 'config/axios.config'
import { useConversionRate } from 'hook/useConversionRate'
import bitcoinIcon from 'images/bitcoin-wallet.svg'
import bnbIcon from 'images/coins/bnb.png'
import pepeIcon from 'images/coins/pepe.png'
import shibIcon from 'images/coins/shib.png'
import solanaIcon from 'images/coins/solana.png'
import ethIcon from 'images/eth-wallet.svg'
import tetherIcon from 'images/tether-wallet.svg'
import usdcIcon from 'images/usdc-wallet.svg'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { equivalentAmount, isAmountValid } from 'utils'
import { errorHandler } from 'utils/errorHandler'
import externalWalletPaymentModal2 from './externalWalletPayment2.modal'

type Props = {
  yield_type: YieldType
  package_id: string
  minAmount: number
  maxAmount: number
}
type Option = {
  label: string
  value: string
}
// usdt, usdc, bitcoin, eth, solana, bnb, shib, trump, pepe,
const supportedCoins: { icon: string; label: AllowedCurrency; networks: Option[] }[] = [
  {
    icon: bitcoinIcon,
    label: 'BTC',
    networks: [{ label: 'BITCOIN', value: 'BITCOIN' }],
  },
  {
    icon: ethIcon,
    label: 'ETH',
    networks: [{ label: 'ERC20', value: 'ERC20' }],
  },
  {
    icon: tetherIcon,
    label: 'USDT',
    networks: [
      { label: 'BEP20', value: 'BEP20' },
      { label: 'TRC20', value: 'TRC20' },
      { label: 'ERC20', value: 'ERC20' },
    ],
  },
  {
    icon: usdcIcon,
    label: 'USDC',
    networks: [
      { label: 'USDC', value: 'USDC' },
      { label: 'BEP20', value: 'BEP20' },
    ],
  },
  // {
  //   icon: ltcIcon,
  //   label: 'LTC',
  // },
  {
    icon: solanaIcon,
    label: 'SOL',
    networks: [{ label: 'Solana', value: 'Solana' }],
  },
  {
    icon: bnbIcon,
    label: 'BNB',
    networks: [{ label: 'BEP20', value: 'BEP20' }],
  },
  {
    icon: shibIcon,
    label: 'SHIB',
    networks: [{ label: 'ERC20', value: 'ERC20' }],
  },
  // {
  //   icon: tetherIcon,
  //   label: 'TRUMP',
  //   networks: [{ label: 'ERC20', value: 'ERC20' }],
  // },
  {
    icon: pepeIcon,
    label: 'PEPE',
    networks: [{ label: 'ERC20', value: 'ERC20' }],
  },
]

export default NiceModal.create(({ package_id, minAmount, maxAmount }: Props) => {
  const modal = useModal()
  const externalWalletPayment2 = useModal(externalWalletPaymentModal2)

  const [amount, setAmount] = useState<string>('')
  const [selectedCoin, setSelectedCoin] = useState(supportedCoins[0])
  const [selectedNetwork, setSelectedNetwork] = useState<Option>(supportedCoins[0].networks[0])
  const [error, setError] = useState<string>('')

  const { data: conversionRate, isLoading } = useConversionRate()

  const { data } = useQuery<PortfolioStatistics>('portfolio-statistics', async () => {
    try {
      const res = await privateRequest.get('user/wallet/portfolio')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const handleCoinChange = (coin: { icon: string; label: AllowedCurrency; networks: Option[] }) => {
    setSelectedCoin(coin)
    setSelectedNetwork(coin.networks[0]) // Set first available network
  }

  const handleNetworkChange = (event: { target: { value: Option } }) => {
    setSelectedNetwork(event.target.value)
  }

  // const requestForCoinbaseAddress = useMutation<
  //   CoinBasePaymentResponse,
  //   Error,
  //   {
  //     packageId: string
  //     amount: string
  //     paymentProvider: 'coinbase'
  //   }
  // >(async (payload) => {
  //   try {
  //     const res = await privateRequest.post('user/investment/subscribe', payload)
  //     return res.data.payment
  //   } catch (error) {
  //     errorHandler(error)
  //   }
  // })
  const requestForNowPaymentAddress = useMutation<
    InvestmentListData,
    Error,
    {
      packageId: string
      amount: string
      paymentProvider: 'nowPayments'
      paymentCoin: AllowedCurrency
      paymentChain: string
    }
  >(async (payload) => {
    try {
      const res = await privateRequest.post('user/investment/subscribe', payload)
      return res.data.subscription
    } catch (error) {
      errorHandler(error)
    }
  })

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!isAmountValid(value)) return
    setAmount(value)
  }

  const convertedAmount = equivalentAmount(
    Number(amount),
    conversionRate,
    selectedCoin.label,
  )?.toFixed(6)

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let errorMsg = ''

    if (!amount) {
      errorMsg = 'Please enter amount'
    } else if (Number(amount) === 0) {
      errorMsg = `Equivalent Amount must be greater than ${minAmount}`
    } else if (Number(amount) < minAmount) {
      errorMsg = `Equivalent Amount must be greater than ${minAmount}`
    }
    //  else if (Number(amount) > maxAmount) {
    //   errorMsg = `Equivalent Amount must be less than ${maxAmount}`
    // }

    if (errorMsg) {
      setError(errorMsg)
      return
    }

    modal.remove()

    // const requestForCoinbaseAddressToast = toast.loading('Your payment is request processing...')
    const requestForNowPaymentToast = toast.loading('Your payment is request processing...')

    requestForNowPaymentAddress
      .mutateAsync({
        amount,
        packageId: package_id,
        paymentProvider: 'nowPayments',
        paymentCoin: selectedCoin.label,
        paymentChain: selectedNetwork.value,
      })
      .then((res) => {
        toast.dismiss(requestForNowPaymentToast)
        externalWalletPayment2.show({
          investmentId: res._id,
        })
      })
      .catch((err) => {
        toast.dismiss(requestForNowPaymentToast)
        toast.error(err.message || 'Something went wrong')
      })
  }
  //   requestForCoinbaseAddress
  //     .mutateAsync({
  //       amount,
  //       packageId: package_id,
  //       paymentProvider: 'coinbase',
  //     })
  //     .then((res) => {
  //       toast.dismiss(requestForCoinbaseAddressToast)
  //       // Redirecting user to the hosted_url
  //       if (res?.hosted_url) {
  //         window.location.href = res.hosted_url

  //       } else {
  //         externalWalletPayment.show({
  //           coin: selectedCoin.label,
  //           data: res,
  //         })
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error(err.message || 'Something went wrong')
  //     })
  // }

  return (
    <Modal
      title='Deposit'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={submitHandler}>
        <Input
          label='Amount'
          value={amount}
          onChange={changeHandler}
          afterFix={
            <GradientText direction='bg-gradient-to-b' className='mb-3 cursor-pointer'>
              <span onClick={() => setAmount(String(data?.balance))}>MAX</span>
            </GradientText>
          }
          prefix={<span className='mr-1'>$</span>}
          error={!!error}
          helpText={error}
        />
        {/* <p className='font-medium text-sm text-mute mt-1'>
          ≈ {Number(convertedAmount) || ''} {selectedCoin.label}
        </p> */}
        <div className='mt-9 text-xs font-normal text-white flex gap-3 flex-wrap items-center'>
          Coin
          {supportedCoins.map((coin, index) => (
            <Button
              key={index}
              type='button'
              variant={selectedCoin.label === coin.label ? 'contained' : 'outlined'}
              onClick={() => handleCoinChange(coin)}
              className='flex gap-2 items-center !px-5'
            >
              <img src={coin.icon} alt='' />
              {coin.label}
            </Button>
          ))}
        </div>
        <div className='mt-6'>
          <Select
            label='Network'
            name='network'
            value={selectedNetwork}
            options={selectedCoin.networks}
            onChange={handleNetworkChange}
            error={!!error}
            helpText={error}
            containerClass='w-full'
          />
        </div>

        <p className='text-xs font-medium capitalize mt-10'>
          By proceeding, you agree to the{' '}
          <a
            target='_blank'
            referrerPolicy='no-referrer'
            href={`${process.env.REACT_APP_WEB_URL}/investment-risk`}
            rel='noreferrer'
          >
            <GradientText direction='bg-gradient-to-b'>risk warning and disclaimer</GradientText>
          </a>
        </p>
        <Button disabled={isLoading} className='mt-4' fullWidth>
          Next
        </Button>
      </form>
    </Modal>
  )
})
