import clsx from 'clsx'
import NoData from 'components/NoData'
import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

type paramsProps = {
  criteria?: string | null
  flug?: string | null
}

export default function TransactionHistoryPayout({ criteria }: paramsProps) {
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    TransactionResponse,
    Error
  >(
    ['transaction-history-payout', criteria],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `user/wallet/transactions?page=${pageParam}&limit=20&criteria=${'payout'}`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []
  return (
    <div className='card'>
      <CardTitle title='Payout Transaction History' />

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>TxID</td>
                <td>Amount</td>
                <td>Note</td>
                <td>Type</td>
                <td>Status</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.createdAt)}</td>
                  <td>{row._id}</td>
                  <td>{row.amount?.toFixed(2)} USDT</td>
                  <td>{row.note}</td>
                  <td className='capitalize'>
                    {row.criteria === 'commission' && row.flag === 'subscription_commission'
                      ? 'Referral'
                      : row.criteria}
                  </td>
                  <td className={`text-${StatusColorFinder(row.status)} capitalize`}>
                    {row.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
        {dataList.length === 0 && !isLoading && <NoData />}
      </div>
    </div>
  )
}
