import AIChatIcon from 'components/icons/AIChatIcon'
import AssetIcon from 'components/icons/AssetIcon'
import CryptoIcon from 'components/icons/CryptoIcon'
import ForexIcon from 'components/icons/ForexIcon'
import InvestIcon from 'components/icons/InvestIcon'
import PortfolioIcon from 'components/icons/PortfolioIcon'

import SettingsIcon from 'components/icons/SettingsIcon'
import StockIcon from 'components/icons/StockIcon'
import { FaUsers } from 'react-icons/fa'
import { RxDashboard } from 'react-icons/rx'

export const drawerLinks: DrawerLink[] = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: RxDashboard,
  },
  {
    label: 'Invest',
    link: '/invest',
    icon: InvestIcon,
  },
  {
    label: 'Portfolio',
    link: '/portfolio',
    icon: PortfolioIcon,
  },
  {
    label: 'My Investors',
    link: '/my-network',
    icon: FaUsers,
  },
  {
    label: 'AI Terminal ',
    link: '/ai-trminal',
    icon: AIChatIcon,
  },
  // {
  //   label: 'Academy',
  //   link: '/academy',
  //   icon: AcademyIcon,
  // },
  {
    label: 'Crypto',
    link: '/crypto',
    icon: CryptoIcon,
  },
  {
    label: 'T-Stocks',
    link: '/t-stocks',
    icon: StockIcon,
  },
  {
    label: 'Forex',
    link: '/forex',
    icon: ForexIcon,
  },
  {
    label: 'Hard asset',
    link: '/hard-asset',
    icon: AssetIcon,
  },
  // {
  //   label: 'Reports',
  //   link: '/reports',
  //   icon: ReportsIcon,
  // },
  {
    label: 'Settings',
    link: '/settings',
    icon: SettingsIcon,
  },
]
