import TransactionHistory from 'features/transaction-history/TransactionHistory'
import TransactionHistoryInvestment from 'features/transaction-history/TransactionHistoryInvestment'
import TransactionHistoryPayout from 'features/transaction-history/TransactionHistoryPayout'
import TransactionHistoryReferral from 'features/transaction-history/TransactionHistoryReferral'
import TransactionHistoryWithdraw from 'features/transaction-history/TransactionHistoryWithdraw'
import { useLocation } from 'react-router-dom'
export default function TransactionHistoryPage() {
  const { search } = useLocation() // ✅ Get query parameters
  const queryParams = new URLSearchParams(search)
  const type: string | null = queryParams.get('type') // ✅ Extract "type"
  return (
    <>
      {type === 'withdraw' && <TransactionHistoryWithdraw />}
      {type === 'referral-reward' && <TransactionHistoryReferral />}
      {type === 'payout' && <TransactionHistoryPayout />}
      {type === 'investment' && <TransactionHistoryInvestment />}
      {!type && <TransactionHistory />} {/* Default component if no type */}
    </>
  )
}
